<template>
  <n-auth-panel>
    <template v-slot:heading>
      <n-html :text="$t('auth.login.title')" />
    </template>

    <template v-slot:content>
      <n-html :text="$t('auth.login.subtitle')" />
    </template>

    <div class="space-y-4">
      <n-form-group
        id="input-group-email"
        label-for="input-email"
        :label="$t('form.email.label')"
        :invalid-feedback="$t('form.email.feedback.invalid')"
        :state="form_states.email"
      >
        <n-form-input-default
          id="input-email"
          :placeholder="$t('form.email.placeholder')"
          v-model="email"
          type="email"
        />
      </n-form-group>

      <n-form-group
        id="input-group-password"
        :label="$t('form.password.label')"
        label-for="input-password"
        :invalid-feedback="$t('form.password.feedback.invalid')"
        :state="form_states.password"
      >
        <n-form-input-password
          id="input-password"
          :placeholder="$t('form.password.placeholder')"
          v-model="password"
          v-on:keyup.enter.native="login"
        />
      </n-form-group>
    </div>

    <div class="flex flex-col font-base text-xs space-y-2">
      <p v-show="password_expired">
        <n-html :text="$t('errors.login.password_expired')" />
      </p>
      <p v-show="incorrect_login" class="text-brand-red-500">
        <n-html :text="$t('errors.login.incorrect_login')" />
      </p>
      <p v-show="error" class="text-brand-red-500">
        <n-html :text="$t('errors.login.error')" />
      </p>
    </div>

    <n-form-group
      class="mt-11"
      id="button-group-login"
      :invalid-feedback="$t('auth.login.error')"
      :state="form_states.login"
    >
      <n-button-regular full-width @click.native="login">
        <n-html :text="$t('auth.login.button')" />
      </n-button-regular>
    </n-form-group>

    <div class="flex flex-row justify-between mt-4">
      <a
        class="text-xs text-brand-purple-500 underline cursor-pointer"
        @click="$router.push({ name: 'auth.register' })"
      >
        <n-html :text="$t('auth.login.register')" />
      </a>

      <a
        class="text-xs text-brand-purple-500 underline cursor-pointer"
        @click="$router.push({ name: 'auth.forgot_password' })"
      >
        <n-html :text="$t('auth.login.password_forgot')" />
      </a>
    </div>
  </n-auth-panel>
</template>

<script>
import validation from "../../mixins/validation";

export default {
  name: "pages.auth.login",
  mixin: [validation],
  data: () => ({
    email: "",
    password: "",
    password_expired: false,
    error: false,
    incorrect_login: false,
    form_states: {
      email: null,
      password: null,
      login: null
    }
  }),
  computed: {
    customToken() {
      return this.$route.query.token;
    },
    valid() {
      return (
        !!(this.email && this.password) & this.validateEmail(this.email) &&
        this.validatePassword(this.password)
      );
    }
  },
  created() {
    if (this.customToken) {
      this.loginWithCustomToken();
    }
  },
  methods: {
    checkUserExists() {
      this.$store
        .dispatch("application/userExists", this.email)
        .then(response => {
          if (response.data.allowed) {
            this.password_expired = true;
          } else {
            this.form_states.login = false;
            this.incorrect_login = true;
          }
        })
        .catch(() => {
          this.error = true;
        });
    },
    async login() {
      this.error = false;
      this.incorrect_login = false;
      try {
        await this.$firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password);
      } catch (err) {
        if (err.code === "auth/wrong-password") {
          this.checkUserExists();
          // this.form_states.login = false;
        } else {
          this.checkUserExists();
          this.form_states.login = false;
          console.error(err);
        }
      }
    },
    async loginWithCustomToken() {
      try {
        await this.$firebase.auth().signInWithCustomToken(this.customToken);
      } catch (err) {
        this.form_states.login = false;
        console.error(err);
      }
    }
  },
  watch: {
    email(n) {
      this.form_states.email = n && this.validateEmail(n);
    },
    password(n) {
      this.form_states.password = n && this.validatePassword(n);
    }
  }
};
</script>
